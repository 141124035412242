@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'PT Serif', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-color: #c2e0c3;
}

.btn {
	@apply bg-white hover:scale-105 shadow-md shadow-slate-500 lg:text-3xl px-4 lg:px-6 py-3 rounded-full text-[#d69f2f]
}

#delete-btn {
	@apply text-red-500
}

h1, h2, p, nav {
	@apply text-[#732c70];
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	margin-bottom: 2rem;
	font-size: 1.25rem;
	line-height: 1.5;
	min-height: 3.5rem;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.5rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.withdrawal, .form-control.testing {
	line-height: 1;
	min-height: 1rem;
	margin-bottom: 1rem;
}

.withdrawal-form testing-form {
	font-size: 1.5rem;
}

.withdrawal-notes {
	padding-top: 1rem;
}

.pt-serif-regular {
	font-family: "PT Serif", serif;
	font-weight: 400;
	font-style: normal;
  }

  .pt-serif-bold {
	font-family: "PT Serif", serif;
	font-weight: 700;
	font-style: normal;
  }

  .pt-serif-regular-italic {
	font-family: "PT Serif", serif;
	font-weight: 400;
	font-style: italic;
  }

  .pt-serif-bold-italic {
	font-family: "PT Serif", serif;
	font-weight: 700;
	font-style: italic;
  }

  .form-control > div {
	border: none;
	margin: 0;
	padding: 0;
  }

  .form-control > div > div > div {
	margin: 0;
	padding: 0;
  }

  .select > div > div > div {
	padding-left: .5rem;
	padding-top: .3rem;
  }

  .file {
	padding-top: .5rem;
  }

  .file-selector-btn{
	background-color: #84BF86;
	color: #ffffff !important;
	transition: all .2s ease-in-out;
	border: none;
	border-radius: 5px;
	padding: .2rem .5rem;
  }

  .file-selector-btn:hover {
	background-color: #d69f2f;
  }

  .card-container {
	display: grid;
	grid-template-columns: repeat(4, minmax(140px, 1fr));
	gap: 1rem;
	width: 90%;
	max-width: 1200px;
	margin: auto;
  }

  .card-container-lg {
	display: grid;
	grid-template-columns: repeat(2, minmax(140px, 1fr));
	gap: 1rem;
	width: 90%;
	max-width: 1200px;
	margin: auto;
	margin-top: 1rem;
  }

  .card-container-xl {
	display: grid;
	grid-template-columns: repeat(1, minmax(140px, 1fr));
	gap: 1rem;
	width: 90%;
	max-width: 1200px;
	margin: auto;
	margin-top: 1rem;
	height: fit-content;
  }

  .card {
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: start;
	flex-direction: column;
	background-color: #c2e0c3;
	aspect-ratio: 1 / .6;
	text-align: center;
  }

  .card-lg {
	aspect-ratio: 1 / .8;
  }

  .card-records {
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: start;
	flex-direction: column;
	background-color: #c2e0c3;
	text-align: center;
  }

  .card p {
	margin: 0;
  }

.card-header {
	background-color: #84BF86;
	width:100%;
	color: #732c70;
	font-size: 1.3rem;
	font-weight: 700;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.card-body {
	font-size: 1.2rem;
	font-weight: 700;
	padding: 0 10px;
	margin-top: 10px;
}

.card-body.records {
	width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 1.2rem;
}

thead, tr, th, td {
	border: 1px solid #732c70;
	padding: 0.5rem;
	background-color: white;
}
